body,
.app-page-wrapper {
  min-height: 100%;
  overflow: unset;
  margin: 0;
}

#root {
  min-height: 100%;
}

.ui.grid.tight > .column:first-child {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.ui.grid.tight > .column:last-child {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.ui[class*="very relaxed"].list:not(.horizontal) > .item {
  padding-top: 13.714px !important;
  padding-bottom: 13.714px !important;
}
.ui.fluid.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ui.menu {
  border-radius: 0 !important;
}
.ui.menu .header {
  font-weight: 300 !important;
}
.ui.menu .header.active {
  font-weight: 500 !important;
}

/* -------------------------------------------------- */

.u-body {
  margin: 30px !important;
}

/* -------------------------------------------------- */

.u-page-dispatcher .u-body {
  margin: 0 !important;
  /*margin: -14px 0 0 0 !important;*/
  /*overflow: hidden;*/
}
